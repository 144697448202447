<template>
  <div class="page-wrapper">
    <h1 class="title">{{ displayText.pageTitle }}</h1>
    <BaseElForm ref="formRef" :model="formData" :rules="formRules">
      <BaseElFormItem prop="email" :label="displayText.formItemLabel.email">
        <BaseElInput
          v-model="formData.email"
          :placeholder="displayText.placeholder.email"
        />
      </BaseElFormItem>
    </BaseElForm>

    <div class="flex flex-col items-center" style="gap: 24px;">
      <p class="text-center text-sub text-gray-60">{{ displayText.hint }}</p>
      <RouterLink to="/login">
        <BaseElButton type="text">
          {{ displayText.btn.backLogin }}
        </BaseElButton>
      </RouterLink>

      <BaseElButton :loading="loading" type="primary" @click="onSubmit">
        {{ displayText.btn.submit }}
      </BaseElButton>
    </div>
  </div>
</template>

<script>
import { SendForgetPasswordAuthnEmail } from '@/api/auth'
import { useBaseForm } from '@/use/useForm'
import { emailRules, noEmptyRules } from '@/validation'
import { computed, defineComponent, onBeforeMount } from 'vue'
import store from '@/store'
import { get } from 'lodash'
import { useRoute, useRouter } from 'vue-router/composables'
import { i18n } from '@/plugins/i18n/i18n'

export default defineComponent({
  name: 'ForgotPassword',
  setup (props, { emit }) {
    const router = useRouter()
    const route = useRoute()
    const { formRef, formData, initFormData, loading, checkForm } = useBaseForm()
    initFormData({
      email: null,
    })
    const formRules = computed(() => {
      const rules = {
        email: [noEmptyRules(), emailRules()],
      }
      return rules
    })

    const orgId = computed(() => get(store.state, 'org.org.id'))

    const queryEmail = computed(() => get(route.query, 'email'))

    const displayText = computed(() => {
      const text = {
        pageTitle: i18n.t('page.forgetPassword.title'),
        formItemLabel: {
          email: i18n.t('forgetPassword.form.email.label'),
        },
        placeholder: {
          email: i18n.t('forgetPassword.form.email.placeholder'),
        },
        btn: {
          submit: i18n.t('forgetPassword.button.sendResetLink.text'),
          backLogin: i18n.t('forgetPassword.button.backLogin.text'),
        },
        hint: i18n.t('forgetPassword.hint.contactAdmin.text'),
      }
      return text
    })

    const onSubmit = async () => {
      if (loading.value) return
      const isValid = await checkForm(formRef.value)
      if (!isValid) {
        loading.value = false
        return
      }

      const [, err, rawErr] = await SendForgetPasswordAuthnEmail({
        orgId: orgId.value,
        email: formData.email,
      })
      loading.value = false

      if (err) {
        if (get(rawErr, 'errorCode') === 'FORGET_PASSWORD_AUTHN_EXPIRED') {
          window.$message.error(err)
          setTimeout(() => {
            router.push({ name: 'Login' })
          }, 1500)
        }
        if (get(rawErr, 'errorCode') === 'EMAIL_LIMIT_EXCEEDED') {
          window.$message.error(err)
          return
        }
      }
      window.$message.success(i18n.t('forgetPassword.toast.resetLinkSent.text'))
    }

    onBeforeMount(() => {
      if (queryEmail.value) {
        formData.email = queryEmail.value
      }
    })

    return { displayText, formRules, formRef, formData, loading, onSubmit }
  },
})
</script>

<style lang="postcss" scoped>
.page-wrapper {
  @apply flex flex-col items-center justify-center h-full;
}
.title {
  @apply text-[32px] font-medium text-center;
}
</style>
